import { ReactNode } from "react"
import { Box, Flex, Label } from "theme-ui"

export const ColorCheckboxWrapper: React.FC<{
  children?: ReactNode
  colors: string[]
}> = ({ colors = [], children }) => {
  return (
    <Label
      sx={{
        display: "flex",
        alignItems: "center",
        gap: 8,
        minWidth: 150,
        justifyContent: "space-between",
        pr: 8,
        mt: [12, null, null, 0],
        borderRadius: "3px",
        "&:first-of-type": {
          mt: 0
        },
        "&:focus-within": {
          backgroundColor: "grey.4"
        }
      }}
    >
      {children}

      <Flex sx={{ width: "auto" }}>
        {colors
          .sort()
          .slice(0, 5)
          .reverse()
          ?.map((hex, index) => (
            <Box
              key={hex + index}
              sx={{
                backgroundColor: hex,
                borderRadius: "50%",
                width: 12,
                height: 12,
                ml: -4
              }}
            />
          ))}
      </Flex>
    </Label>
  )
}
