//jsxhook

import {
  Button,
  ButtonVariants,
  Fonts,
  H2,
  HollowLightButton,
  Label1,
  Label3,
  LinkVariants,
  Mobile,
  Shadows,
  simpleFadeMotion,
  SmallArrowUp,
  StandardXAxisPadding,
  TechnicalParagraph,
  TextVariants,
  zIndices
} from "@sixty-six-north/ui-system"
import { AnimatePresence, m } from "framer-motion"
import _kebabCase from "lodash/kebabCase"
import React, { useRef } from "react"
import { useTranslation } from "react-i18next"
import { useInView } from "react-intersection-observer"
import { Flex } from "theme-ui"
import { useLayout } from "../../layout/LayoutState"
import SortedProductGrid from "../../product/components/SortedProductGrid"
import { CoreProductInformation } from "../../product/models/DetailedProductInformation"
import { Shelf } from "../../shelves/ShelvesDal"
import {
  useCurrentCategory,
  useSubCategory,
  GroupedProducts
} from "../CategoryHooks"
import { useProductListing } from "../ProductListing"

/**
 * Displays a list of products grouped by their style.
 * @param shelves Array of Shelf to order products into
 * @param splitProductsByRootCategory When true, duplicates products for each
 * of their root categories.
 * @constructor
 */
export const ProductShelf: React.FC<{
  shelves: Shelf[]
  splitProductsByRootCategory?: boolean
}> = ({ shelves = [], splitProductsByRootCategory = false }) => {
  const currentCategory = useCurrentCategory()
  const currentSubCategory = useSubCategory()
  const productListing = useProductListing()
  const groupedProducts = productListing.groupedProducts
  const productShelves = groupProductsIntoShelves(
    shelves,
    groupedProducts.filter(it => it.products.length > 0)
  )

  return (
    <Flex
      sx={{
        position: "relative",
        flexDirection: "column",
        mb: 32,
        gap: [14, null, 128]
      }}
    >
      {productShelves.length !== 0 ? (
        productShelves.map((shelf, idx) => {
          return (
            <ShelfFragment
              key={(currentCategory?.key || "shelf") + "-" + shelf.id}
              shelf={shelf}
              idx={idx}
              splitProductsByRootCategory={splitProductsByRootCategory}
            />
          )
        })
      ) : (
        <NoResultsFragment clearAll={productListing.filter.clearAll} />
      )}
    </Flex>
  )
}

export const NoResultsFragment: React.FC<{ clearAll: () => void }> = ({
  clearAll
}) => {
  const { t } = useTranslation("products")

  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 16,
        padding: ["2rem", null, "3rem", "4rem"],
        margin: "0 auto",
        width: ["100%", null, "80%", "60%"]
      }}
    >
      <H2>{t("noFilterResults")}</H2>
      <Label1
        sx={{
          fontFamily: Fonts.display,
          display: "flex",
          flexDirection: ["column", "row"],
          alignItems: ["flex-start", "center"],
          color: "grey.1",

          button: {
            variant: `links.${LinkVariants.link_h6}`,
            color: "blue.0",
            width: "auto",
            padding: [0, 8],
            ":hover, :focus": {
              color: "blue.2",
              textDecoration: "underline"
            }
          }
        }}
      >
        {t("letsGetBackOnTrack")}
        <HollowLightButton onClick={clearAll}>
          {t("clearAllFilters")}
        </HollowLightButton>
      </Label1>
    </Flex>
  )
}

export const ShelfFragment: React.FC<{
  shelf: PopulatedShelf
  idx: number
  splitProductsByRootCategory?: boolean
}> = ({ shelf, idx, splitProductsByRootCategory }) => {
  const { t } = useTranslation("products")
  const { ref, inView } = useInView({
    initialInView: true,
    threshold: 0.01,
    rootMargin: "0px 0px 600px 600px"
  })
  const containerRef = useRef<HTMLDivElement | null>(null)
  const shelfId = _kebabCase(shelf.name.replace(/^\d+/, ""))
  const layoutState = useLayout().state

  return (
    <>
      <Flex
        id={shelfId}
        ref={containerRef}
        key={`inner-shelf-${shelf.id}`}
        sx={{
          flexDirection: ["column", "column", "column", "row"],
          position: "relative",
          flex: 1
        }}
      >
        <AnimatePresence>
          <Mobile
            sx={{
              position: "sticky",
              visibility: !inView ? "visible" : "hidden",
              height: "auto",
              top: [168],
              zIndex: zIndices.stickyBarOverlay,
              py: 8,
              bg: "white.0",
              boxShadow: Shadows.small,
              flex: 1,
              px: StandardXAxisPadding,
              display: "flex",
              alignItems: "center"
            }}
          >
            <m.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ ...simpleFadeMotion }}
              style={{ width: "100%" }}
            >
              <Flex
                sx={{
                  justifyContent: "space-between",
                  alignItems: "center"
                }}
              >
                <Label3 sx={{ variant: `text.${TextVariants.label3Bold}` }}>
                  {shelf.name}
                </Label3>
                <Flex sx={{ justifySelf: "flex-end" }}>
                  <Button
                    variant={ButtonVariants.actionableTagSecondary}
                    key={`mobile-top-button-${shelf.id}`}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    <Flex sx={{ gap: 8, alignItems: "center" }}>
                      <Label3>{t("backToTop")}</Label3>
                      <SmallArrowUp width={8} />
                    </Flex>
                  </Button>
                </Flex>
              </Flex>
            </m.div>
          </Mobile>
        </AnimatePresence>
        <Flex
          key={`shelf-info-${shelf.id}`}
          sx={{
            flexDirection: ["column", "column", "column", "row"],
            px: StandardXAxisPadding,
            position: "relative",
            flex: 1,
            pt: [0, 0, 48, 48]
          }}
        >
          <Flex
            ref={ref}
            sx={{
              flex: 0.25,
              flexDirection: "column",
              top: [null, null, null, 207],
              height: ["auto", "20%"],
              pb: 16,
              position: [null, null, null, "sticky"],
              zIndex: [null, null, null, zIndices.inPage],
              bg: "white.0",
              mr: [0, 0, 16, 16, 24]
            }}
          >
            <H2 sx={{ variant: `text.${TextVariants.label2Bold}` }}>
              {shelf.name}
            </H2>

            <TechnicalParagraph
              sx={{ mt: 4, color: "grey.1", maxWidth: "42ch" }}
            >
              {shelf.description}
            </TechnicalParagraph>
          </Flex>
          <Flex sx={{ flex: 1 }}>
            <SortedProductGrid
              idx={idx}
              showCategoryBadge={splitProductsByRootCategory}
              products={shelf.products}
              mobileProductsMultiColumnGrid={
                layoutState.mobileProductsMultiColumnGrid
              }
            />
          </Flex>
        </Flex>
      </Flex>
    </>
  )
}

interface PopulatedShelf {
  id: string
  name: string
  description: string
  products: CoreProductInformation[]
}

const groupProductsIntoShelves = (
  shelves: Shelf[],
  groupedProducts: GroupedProducts[]
): PopulatedShelf[] => {
  return shelves
    .map(shelf => {
      const group = groupedProducts.find(g => g.styleKey === shelf.id)
      return {
        products: group?.products || [],
        ...shelf
      }
    })
    .filter(it => it.products.length > 0)
}
