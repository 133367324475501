import { Currency } from "@sixty-six-north/i18n"
import { Option } from "funfix-core"
import { CoreProductInformation } from "../models/DetailedProductInformation"
import { Price } from "../models/Price"
import { OrderBy } from "../ProductDalTypes"

function price(
  prices: Price[],
  currency: Currency,
  defaultValue: number = 0
): number {
  return Option.of(prices)
    .flatMap(p => Option.of(p.find(it => it.value.currencyCode === currency)))
    .map(it => it.value.centAmount)
    .getOrElse(defaultValue)
}

const productPriceComparison = (
  productA: CoreProductInformation,
  productB: CoreProductInformation,
  currency: Currency,
  sortOrderInversionMultiplier: number
) => {
  const priceA = price(productA.prices, currency)

  const priceB = price(productB.prices, currency)

  if (priceA === priceB) {
    return productA.id > productB.id ? 1 : -1
  }
  return (priceA > priceB ? 1 : -1) * sortOrderInversionMultiplier
}

export const sortProductsBy = (
  products: CoreProductInformation[],
  sortBy: OrderBy,
  currency: Currency
): CoreProductInformation[] => {
  const multiplierWibble = sortBy === OrderBy.priceAsc ? 1 : -1
  return [...products].sort((a, b) =>
    productPriceComparison(a, b, currency, multiplierWibble)
  )
}
