//jsxhook

import {
  Button,
  Desktop,
  FilterIcon,
  HeadingRegularVariants,
  Label2,
  Label3,
  Mobile,
  MultiColumnLayoutIcon,
  OutlineLightButton,
  SingleColumnLayoutIcon,
  simpleFadeMotion,
  SmallArrowDown,
  SmallArrowUp,
  StandardXAxisPadding,
  TextVariants,
  zIndices
} from "@sixty-six-north/ui-system"
import { TAG_FILTER_CONFIG, useTagFilter } from "filters/TagFilterHooks"
import { m } from "framer-motion"
import { OrderBy } from "product/ProductDalTypes"
import React, { createRef, Fragment, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Flex } from "theme-ui"
import { usePreferredLanguage } from "../../i18n/StoreHooks"
import { useCurrentCategory } from "../CategoryHooks"
import { useProductListing } from "../ProductListing"
import { ColumnModeToggle } from "./ColumnModeToggle"
import { FilterModal, OutlineFilterButton } from "./FilterModal"
import { FiltersMenu } from "./FiltersMenu"
import { SortMenu } from "./SortMenu"
import { ViewOptions } from "./ViewOptions"

export const ProductNavBar: React.FC = ({}) => {
  const productListing = useProductListing()
  const currentCategory = useCurrentCategory()
  const language = usePreferredLanguage()
  const { t } = useTranslation("category")

  const [showFilterModal, setShowFilterModal] = useState(false)
  const [showFilterMenu, setShowFilterMenu] = useState(false)
  const [showSortMenu, setShowSortMenu] = useState(false)
  const [showBackToTop, setShowBackToTop] = useState<boolean>(false)

  useEffect(() => {
    const handleScrollChange = () => {
      if (window.scrollY > 600) {
        setShowBackToTop(true)
      } else {
        setShowBackToTop(false)
      }
    }

    window.addEventListener("scroll", handleScrollChange)
    return () => window.removeEventListener("scroll", handleScrollChange)
  }, [])

  const filterButtonRef = createRef<HTMLButtonElement>()
  const sortButtonRef = createRef<HTMLButtonElement>()
  const tagFilter = useTagFilter()

  return (
    <Fragment>
      <Flex
        sx={{
          boxSizing: "content-box",
          flexDirection: "row",
          alignItems: "center",
          position: "sticky",
          height: 78,
          top: 89,
          zIndex: zIndices.stickyBars,
          bg: ["white.0", "white.0", "grey.5"],
          border: "1px solid",
          borderColor: "grey.4"
        }}
      >
        <Flex
          sx={{
            flex: 1,
            zIndex: zIndices.stickyBars,
            px: StandardXAxisPadding
          }}
        >
          <Flex sx={{ mr: "auto", gap: 24 }}>
            <Flex
              sx={{
                flexDirection: "column",
                height: "auto",
                flexFlow: "column wrap",
                justifyContent: "center",
                pr: [0, null, null, 16, 48]
              }}
            >
              <h3
                sx={{
                  variant: `text.${HeadingRegularVariants.sixth}`,
                  color: "black.0"
                }}
              >
                {(tagFilter && TAG_FILTER_CONFIG[tagFilter]?.name[language]) ||
                  (currentCategory && currentCategory.name[language]) ||
                  ""}
              </h3>
              <Label3 sx={{ color: "grey.1" }}>
                {`${productListing.filter.filteredProducts.length} ${t(
                  "products"
                )}`}
              </Label3>
            </Flex>

            <Desktop
              sx={{ display: ["none", "none", "flex"], alignItems: "center" }}
            >
              <ViewOptions />
            </Desktop>
          </Flex>

          <Desktop>
            <Flex
              sx={{
                justifySelf: "flex-end",
                alignItems: "center",
                gap: 8
              }}
            >
              <Button
                ref={filterButtonRef}
                variant={"outlineLight"}
                aria-expanded={showFilterMenu}
                onClick={() => setShowFilterMenu(!showFilterMenu)}
              >
                <Flex sx={{ gap: 8, alignItems: "center" }}>
                  <FilterIcon stroke="#262626" fill="#F4F4F4" />
                  <Label2>{t("filters", { defaultValue: "Filters" })}</Label2>
                  {showFilterMenu ? (
                    <SmallArrowUp height={8} width={8} />
                  ) : (
                    <SmallArrowDown height={8} width={8} />
                  )}
                </Flex>
              </Button>

              <Flex>
                <Button
                  variant={"outlineLight"}
                  ref={sortButtonRef}
                  aria-expanded={showSortMenu}
                  onClick={() => {
                    setShowSortMenu(!showSortMenu)
                  }}
                >
                  <Flex
                    sx={{
                      gap: 8,
                      alignItems: "center"
                    }}
                  >
                    <Label2>
                      {t("sortedBy", { defaultValue: "Sorted by" })}{" "}
                      <Label2
                        sx={{
                          variant: `text.${TextVariants.label2Bold}`,
                          textTransform: "lowercase"
                        }}
                      >
                        {productListing.ordering === OrderBy.priceAsc
                          ? t("lowestPrice", {
                              defaultValue: "Price low to high"
                            })
                          : t("highestPrice", {
                              defaultValue: "Price high to low"
                            })}
                      </Label2>
                    </Label2>
                    {showSortMenu ? (
                      <SmallArrowUp height={8} width={8} />
                    ) : (
                      <SmallArrowDown height={8} width={8} />
                    )}
                  </Flex>
                </Button>

                {showSortMenu && (
                  <SortMenu
                    triggerRef={sortButtonRef}
                    onClose={() => setShowSortMenu(false)}
                  />
                )}
              </Flex>

              {showBackToTop && (
                <m.div
                  initial={{ opacity: 0, x: 200 }}
                  animate={{ opacity: 1, x: 0 }}
                  transition={{ ...simpleFadeMotion }}
                >
                  <Button
                    variant={"outlineLight"}
                    onClick={() =>
                      window.scrollTo({ top: 0, behavior: "smooth" })
                    }
                  >
                    <Flex sx={{ gap: 8, alignItems: "center" }}>
                      <Label2>
                        {t("backToTop", { defaultValue: "Back to top" })}
                      </Label2>
                      <SmallArrowUp height={8} width={8} />
                    </Flex>
                  </Button>
                </m.div>
              )}
            </Flex>
          </Desktop>

          <Mobile>
            <ColumnModeToggle />
            <OutlineFilterButton onClick={() => setShowFilterModal(true)} />
          </Mobile>
        </Flex>

        {showFilterMenu && (
          <FiltersMenu
            triggerRef={filterButtonRef}
            onClose={() => {
              setShowFilterMenu(false)
              filterButtonRef.current?.focus()
            }}
          />
        )}
      </Flex>
      <FilterModal
        show={showFilterModal}
        onClose={() => setShowFilterModal(false)}
        colors={productListing.colors}
        subColors={productListing.subColors}
        sizes={productListing.sizes}
        styles={productListing.styles}
        functionalities={productListing.functionalities}
        filterService={productListing.filter}
      />
    </Fragment>
  )
}
