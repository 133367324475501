//jsxhook

import { ImageVariants, ProductGrid } from "@sixty-six-north/ui-system"
import { useTagFilter } from "filters/TagFilterHooks"
import React from "react"
import {
  useAllCategoriesLookup,
  useCurrentCategory
} from "../../category/CategoryHooks"
import { useProductListing } from "../../category/ProductListing"
import { usePreferredLanguage, useStoreContext } from "../../i18n/StoreHooks"
import { CoreProductInformation } from "../models/DetailedProductInformation"
import ProductCard from "./ProductCard"
import { sortProductsBy } from "./ProductSorting"

export enum ProductCardVariant {
  ORIGINAL = "ORIGINAL",
  EXP_OCT_22 = "EXP_OCT_22"
}

const SortedProductGrid: React.FC<{
  products: CoreProductInformation[]
  idx: number
  showCategoryBadge?: boolean
  mobileProductsMultiColumnGrid?: boolean
}> = ({
  products,
  showCategoryBadge = false,
  mobileProductsMultiColumnGrid = false,
  idx,
  ...props
}) => {
  const language = usePreferredLanguage()
  const store = useStoreContext()
  const tagFilter = useTagFilter()
  const sortBy = useProductListing().ordering
  const currentCategory = useCurrentCategory()
  const categoryLookup = useAllCategoriesLookup()
  const filterColors = useProductListing()
    .colors.filter(c => c.isActive)
    .map(c => c.filterValue)

  const sortedProducts = sortProductsBy(products, sortBy, store.currency)

  if (sortedProducts.length === 0) {
    return null
  } else {
    return (
      <ProductGrid
        gap={24}
        mobileProductsMultiColumnGrid={mobileProductsMultiColumnGrid}
      >
        {sortedProducts.flatMap((product, index) => {
          const topLevelCategories = categoryLookup.topLevelCategoriesFor(
            product.categoryKeys
          )
          const topLevelCategoryForCurrentCategory = currentCategory
            ? categoryLookup.topLevelCategoryFor([currentCategory?.key])
            : undefined
          const topLevelCategoriesForProduct = showCategoryBadge
            ? topLevelCategories
            : topLevelCategoryForCurrentCategory
              ? [topLevelCategoryForCurrentCategory]
              : topLevelCategories.splice(0, 1)

          return topLevelCategoriesForProduct.map(it => {
            const subCategorySlug =
              categoryLookup.firstSubCategoryUnderTopLevel(product, it.key)
                ?.slug?.[language]
            return (
              <ProductCard
                index={index}
                lazyload={idx !== 0 || index > 2}
                filterColors={filterColors}
                key={`${index}-${store.country}-${it?.key || ""}-${product.id}`}
                product={product}
                categorySlug={it?.slug?.[language]}
                subCategorySlug={subCategorySlug}
                badge={showCategoryBadge ? it?.name[language] : ""}
                imageRatio={ImageVariants.ratio_3x4}
                productCardVariant={ProductCardVariant.EXP_OCT_22}
                border={false}
                tagSlug={tagFilter}
              />
            )
          })
        })}
      </ProductGrid>
    )
  }
}

export default SortedProductGrid
