//jsxhook

import {
  Checkbox,
  FilterCard,
  FilterDropDown,
  FilterSection,
  Label2,
  Shadows,
  TextVariants,
  zIndices
} from "@sixty-six-north/ui-system"
import { Flex } from "@theme-ui/components"
import { ReactNode } from "react"
import { Box, Grid } from "theme-ui"

export interface FilterValue {
  filterLabel: string
  filterValue: string
  isActive: boolean
}

export const filterCardStyle = {
  top: 64,
  zIndex: zIndices.stickyBars,
  borderStyle: "solid",
  borderWidth: "1px",
  borderColor: "grey.3",
  borderTopWidth: "0px",
  boxShadow: Shadows.small
}
export const filterProps = { cardStyle: filterCardStyle }

export const CheckboxFilterWrapper: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  return (
    <Box
      sx={{
        px: 8,
        py: 4,
        borderRadius: "3px",
        "&:focus-within": {
          backgroundColor: "grey.4"
        },
        minWidth: 158
      }}
    >
      {children}
    </Box>
  )
}

export const CheckboxFilter: React.FC<{
  filter: FilterValue
  filterName: string
  labelVariant?: string
  toggleFilter: (value: FilterValue) => void
}> = ({ filter, filterName, labelVariant, toggleFilter }) => {
  return (
    <CheckboxFilterWrapper>
      <Checkbox
        label={filter.filterLabel}
        id={`checkbox-${filter.filterValue}`}
        name={filterName}
        value={filter.filterValue}
        checked={filter.isActive}
        onClick={() => {
          toggleFilter(filter)
        }}
        tabIndex={0}
        labelVariant={labelVariant}
      />
    </CheckboxFilterWrapper>
  )
}
export const CheckboxFilterDropdown: React.FC<{
  filterName: string
  filterLabel: string
  values: FilterValue[]
  toggleFilter: (value: FilterValue) => void
  children?: ReactNode
}> = ({ filterName, filterLabel, values, toggleFilter, children }) => {
  return (
    <FilterDropDown label={filterLabel} {...filterProps}>
      <FilterCard>
        <FilterSection>
          {!!children
            ? children
            : values.map(it => {
                return (
                  <CheckboxFilter
                    key={it.filterValue}
                    filterName={filterName}
                    filter={it}
                    toggleFilter={toggleFilter}
                  />
                )
              })}
        </FilterSection>
      </FilterCard>
    </FilterDropDown>
  )
}

export const CheckboxFilterSection: React.FC<{
  filterName: string
  filterLabel: string
  values: FilterValue[]
  toggleFilter: (value: FilterValue) => void
  children?: ReactNode
}> = ({ filterName, filterLabel, values, toggleFilter, children }) => {
  return (
    <Flex
      sx={{
        flexDirection: "column",
        gap: 8,
        width: ["44%", null, null, null, "auto"]
      }}
    >
      <Label2 sx={{ variant: `text.${TextVariants.label2Bold}`, px: 8 }}>
        {filterLabel}
      </Label2>

      <Grid
        sx={{
          width: "100%",
          gridTemplateRows: `repeat(${
            values.length <= 8 ? values.length : 8
          }, 1fr)`,
          gridTemplateColumns: `repeat(${values.length <= 8 ? 1 : 2}, 1fr)`,
          rowGap: 8,
          columnGap: 36,
          gridAutoFlow: "column"
        }}
      >
        {!!children
          ? children
          : values.map(it => {
              return (
                <CheckboxFilter
                  key={it.filterValue}
                  filterName={filterName}
                  filter={it}
                  toggleFilter={toggleFilter}
                  labelVariant={`text.${TextVariants.label3Bold}`}
                />
              )
            })}
      </Grid>
    </Flex>
  )
}
