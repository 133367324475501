//jsxhook
import {
  ActionableTag,
  ButtonVariants,
  SelectedFiltersBar,
  StandardXAxisPadding
} from "@sixty-six-north/ui-system"
import React from "react"
import { useProductListing } from "../ProductListing"

export const CurrentFilters: React.FC = ({}) => {
  const productListing = useProductListing()

  return (
    <React.Fragment>
      {(productListing.colors.filter(it => it.isActive).length > 0 ||
        productListing.styles.filter(it => it.isActive).length > 0 ||
        productListing.functionalities.filter(it => it.isActive).length > 0 ||
        productListing.sizes.filter(it => it.isActive).length > 0) && (
        <React.Fragment>
          <SelectedFiltersBar
            sx={{
              px: StandardXAxisPadding,
              gap: 12,
              bg: "white.0",
              border: "none"
            }}
          >
            {productListing.colors
              .filter(it => it.isActive)
              .map((it, idx) => {
                return (
                  <ActionableTag
                    variant={ButtonVariants.actionableTagSecondary}
                    onClick={() =>
                      productListing.filter.toggleColor(it.filterValue)
                    }
                    key={idx}
                  >
                    {it.filterLabel}
                  </ActionableTag>
                )
              })}
            {productListing.styles
              .filter(it => it.isActive)
              .map((it, idx) => {
                return (
                  <ActionableTag
                    variant={ButtonVariants.actionableTagSecondary}
                    onClick={() =>
                      productListing.filter.toggleStyle(it.filterValue)
                    }
                    key={idx}
                  >
                    {it.filterLabel}
                  </ActionableTag>
                )
              })}
            {productListing.functionalities
              .filter(it => it.isActive)
              .map((it, idx) => {
                return (
                  <ActionableTag
                    variant={ButtonVariants.actionableTagSecondary}
                    onClick={() =>
                      productListing.filter.toggleFunctionality(it.filterValue)
                    }
                    key={idx}
                  >
                    {it.filterLabel}
                  </ActionableTag>
                )
              })}
            {productListing.sizes
              .filter(it => it.isActive)
              .map((it, idx) => {
                return (
                  <ActionableTag
                    variant={ButtonVariants.actionableTagSecondary}
                    onClick={() =>
                      productListing.filter.toggleSize(it.filterValue)
                    }
                    key={idx}
                  >
                    {it.filterLabel}
                  </ActionableTag>
                )
              })}
          </SelectedFiltersBar>
        </React.Fragment>
      )}
    </React.Fragment>
  )
}
