//jsxhook

import {
  Checkbox,
  FilterIcon,
  Label2,
  Label3,
  Modal,
  OutlineLightButton,
  PrimaryButton,
  Radio,
  TextVariants
} from "@sixty-six-north/ui-system"
import { ColorCheckboxWrapper } from "filters/ColorCheckboxWrapper"
import { OrderBy } from "product/ProductDalTypes"
import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useState,
  ReactNode
} from "react"
import { useTranslation } from "react-i18next"
import { Box, Flex } from "theme-ui"
import {
  CheckboxFilterSection,
  CheckboxFilterWrapper,
  FilterValue
} from "../../filters/FilterComponent"
import { FilteredProductsService, SubColors } from "../../filters/FilterHooks"
import { useProductListing } from "../ProductListing"

export const FilterModal: React.FC<{
  show: boolean
  onClose: () => void
  colors: FilterValue[]
  subColors: SubColors
  sizes: FilterValue[]
  styles: FilterValue[]
  functionalities: FilterValue[]
  filterService: FilteredProductsService
}> = ({
  show,
  onClose,
  colors,
  subColors,
  sizes,
  styles,
  functionalities,
  filterService
}) => {
  const { t } = useTranslation("category")
  const productListing = useProductListing()
  const [selectedColorFilters, setColorFilters] = useState<FilterValue[]>([])
  const [selectedSizeFilters, setSizeFilters] = useState<FilterValue[]>([])
  const [selectedStyleFilters, setStyleFilters] = useState<FilterValue[]>([])
  const [selectedFunctionalityFilters, setFunctionalityFilters] = useState<
    FilterValue[]
  >([])

  useEffect(() => {
    if (show) {
      setColorFilters(colors.filter(it => it.isActive))
      setSizeFilters(sizes.filter(it => it.isActive))

      setStyleFilters(styles.filter(it => it.isActive))
      setFunctionalityFilters(functionalities.filter(it => it.isActive))
    }
  }, [show])

  const onApply = () => {
    filterService.clearColor()
    filterService.clearStyle()
    filterService.clearFunctionality()
    filterService.clearSize()

    selectedColorFilters.forEach(it =>
      filterService.toggleColor(it.filterValue)
    )
    selectedSizeFilters.forEach(it => filterService.toggleSize(it.filterValue))

    selectedStyleFilters.forEach(it =>
      filterService.toggleStyle(it.filterValue)
    )
    selectedFunctionalityFilters.forEach(it =>
      filterService.toggleFunctionality(it.filterValue)
    )

    onClose()
  }

  const toggler = (setFn: Dispatch<SetStateAction<FilterValue[]>>) => {
    return (filter: FilterValue) => {
      setFn(currentFilters => {
        if (currentFilters.indexOf(filter) > -1) {
          return currentFilters.filter(it => it !== filter)
        } else {
          return currentFilters.concat([filter])
        }
      })
    }
  }

  const toggleColor = toggler(setColorFilters)
  const toggleSize = toggler(setSizeFilters)
  const toggleStyle = toggler(setStyleFilters)
  const toggleFunctionality = toggler(setFunctionalityFilters)

  // TODO: Icelandic translations for Image View, Model View & Product View
  return (
    <Modal title={t("filterAndSort")} show={show} close={onClose} paddingX={0}>
      <Flex sx={{ flexDirection: "column", pb: 56 }}>
        <Flex
          sx={{
            flexDirection: "column",
            gap: 24,
            borderBottom: "1px solid",
            borderColor: "grey.4",
            pt: 16,
            pb: 48
          }}
        >
          <fieldset
            sx={{
              border: 0,
              padding: 0,
              margin: 0,
              minWidth: 0,
              display: "flex",
              flexDirection: "column",
              gap: 8,
              px: 24
            }}
          >
            <legend
              sx={{
                variant: `text.${TextVariants.label3Bold}`,
                mb: 8
              }}
            >
              {t("imageView", { defaultValue: "Image View" })}
            </legend>
            <Radio
              id="lowest-image-view"
              name="image-view"
              value="MODEL"
              checked={productListing.imageView === "MODEL"}
              onClick={() => productListing.setImageView("MODEL")}
            >
              <Label3
                sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}
              >
                {t("modelView", { defaultValue: "Model view" })}
              </Label3>
            </Radio>
            <Radio
              id="highest-image-view"
              name="image-view"
              value="PRODUCT"
              checked={productListing.imageView === "PRODUCT"}
              onClick={() => productListing.setImageView("PRODUCT")}
            >
              <Label3
                sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}
              >
                {t("productView", { defaultValue: "Product view" })}
              </Label3>
            </Radio>
          </fieldset>
          <fieldset
            sx={{
              border: 0,
              padding: 0,
              margin: 0,
              minWidth: 0,
              display: "flex",
              flexDirection: "column",
              gap: 8,
              px: 24
            }}
          >
            <legend
              sx={{
                variant: `text.${TextVariants.label3Bold}`,
                mb: 8
              }}
            >
              {t("sortBy", { defaultValue: "Sort By" })}
            </legend>
            <Radio
              id="lowest-price"
              name="price"
              value={OrderBy.priceAsc}
              checked={productListing.ordering === OrderBy.priceAsc}
              onClick={() => productListing.setOrdering(OrderBy.priceAsc)}
            >
              <Label3
                sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}
              >
                {t("lowestPrice", { defaultValue: "Price low to high" })}
              </Label3>
            </Radio>
            <Radio
              id="highest-price"
              name="price"
              value={OrderBy.priceDesc}
              checked={productListing.ordering === OrderBy.priceDesc}
              onClick={() => productListing.setOrdering(OrderBy.priceDesc)}
            >
              <Label3
                sx={{ pl: 4, variant: `text.${TextVariants.label3Bold}` }}
              >
                {t("highestPrice", { defaultValue: "Price high to low" })}
              </Label3>
            </Radio>
          </fieldset>
        </Flex>
        <MobileFilterSection>
          <CheckboxFilterSection
            filterLabel={t("style")}
            filterName={"style"}
            values={productListing.styles}
            toggleFilter={it => {
              productListing.filter.toggleStyle(it.filterValue)
            }}
          >
            {styles.map(it => {
              return (
                <CheckboxFilterWrapper key={it.filterValue}>
                  <Checkbox
                    label={it.filterLabel}
                    id={`checkbox-${it.filterValue}`}
                    name="style"
                    checked={selectedStyleFilters.indexOf(it) > -1}
                    onClick={ev => {
                      toggleStyle(it)
                    }}
                    labelVariant={`text.${TextVariants.label3Bold}`}
                  />
                </CheckboxFilterWrapper>
              )
            })}
          </CheckboxFilterSection>
        </MobileFilterSection>

        <MobileFilterSection>
          <CheckboxFilterSection
            filterLabel={t("color")}
            filterName={"colors"}
            values={productListing.colors}
            toggleFilter={() => {}}
          >
            {productListing.colors.map(it => {
              return (
                <CheckboxFilterWrapper key={it.filterValue}>
                  <ColorCheckboxWrapper
                    key={it.filterValue}
                    colors={productListing.subColors[it.filterValue]}
                  >
                    <Checkbox
                      key={it.filterValue}
                      label={it.filterLabel}
                      id={`checkbox-${it.filterValue}`}
                      name={"colors"}
                      checked={selectedColorFilters.indexOf(it) > -1}
                      onClick={() => toggleColor(it)}
                      labelVariant={`text.${TextVariants.label3Bold}`}
                    />
                  </ColorCheckboxWrapper>
                </CheckboxFilterWrapper>
              )
            })}
          </CheckboxFilterSection>
        </MobileFilterSection>

        <MobileFilterSection>
          <CheckboxFilterSection
            filterLabel={t("size")}
            filterName={"size"}
            values={productListing.sizes}
            toggleFilter={it =>
              productListing.filter.toggleSize(it.filterValue)
            }
          >
            {sizes.map(it => {
              return (
                <CheckboxFilterWrapper key={it.filterValue}>
                  <Checkbox
                    key={it.filterValue}
                    label={it.filterLabel}
                    id={`checkbox-${it.filterValue}`}
                    name={"size"}
                    checked={selectedSizeFilters.indexOf(it) > -1}
                    onClick={ev => {
                      toggleSize(it)
                    }}
                    labelVariant={`text.${TextVariants.label3Bold}`}
                  />
                </CheckboxFilterWrapper>
              )
            })}
          </CheckboxFilterSection>
        </MobileFilterSection>

        {productListing.functionalities.length > 0 && (
          <MobileFilterSection>
            <CheckboxFilterSection
              filterLabel={t("functionality")}
              filterName={"functionality"}
              values={productListing.functionalities}
              toggleFilter={it =>
                productListing.filter.toggleFunctionality(it.filterValue)
              }
            >
              {functionalities.map(it => {
                return (
                  <CheckboxFilterWrapper key={it.filterValue}>
                    <Checkbox
                      label={it.filterLabel}
                      id={`checkbox-${it.filterValue}`}
                      name={"functionality"}
                      checked={selectedFunctionalityFilters.indexOf(it) > -1}
                      onClick={ev => {
                        toggleFunctionality(it)
                      }}
                      labelVariant={`text.${TextVariants.label3Bold}`}
                    />
                  </CheckboxFilterWrapper>
                )
              })}
            </CheckboxFilterSection>
          </MobileFilterSection>
        )}
      </Flex>
      <Box
        sx={{
          p: 24,
          mt: "auto",
          position: "fixed",
          bottom: 0,
          width: "100%",
          bg: "white.0",
          borderTop: "1px solid",
          borderColor: "grey.4"
        }}
      >
        <PrimaryButton sx={{ marginTop: "auto" }} fill={true} onClick={onApply}>
          {t("apply", { defaultValue: "Apply" })}
        </PrimaryButton>
      </Box>
    </Modal>
  )
}

export const OutlineFilterButton: React.FC<{ onClick: () => void }> = ({
  onClick
}) => {
  const { t } = useTranslation("category")
  return (
    <OutlineLightButton onClick={onClick}>
      <FilterIcon stroke="#262626" fill="#F4F4F4" />
      <Label2 sx={{ ml: 4 }}>
        {t("filterAndSort", { defaultValue: "Filter" })}
      </Label2>
    </OutlineLightButton>
  )
}

const MobileFilterSection: React.FC<{ children?: ReactNode }> = ({
  children
}) => {
  return (
    <Flex sx={{ p: 24, borderBottom: "1px solid", borderColor: "grey.4" }}>
      {children}
    </Flex>
  )
}
