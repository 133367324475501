import { FontWeight, Label2, TextVariants } from "@sixty-six-north/ui-system"
import { useTranslation } from "react-i18next"
import { useGoogleAnalytics } from "tagmanager/AnalyticsHooks"
import { BoxProps, Flex } from "theme-ui"
import { useProductListing } from "../ProductListing"

const ImageOptionButton: React.FC<{
  label: string
  selected: boolean
  onClick: () => void
}> = ({ label, selected, onClick }) => {
  const coreStyles = {
    border: "none",
    background: "none",
    display: "flex",
    maxWidth: [null, null, 160],
    borderRadius: 0,
    fontSize: "scale-1",
    fontWeight: FontWeight.displayBold,
    borderBottom: "1px solid black",
    px: 0,
    py: 8
  }

  const nonSelectedStyles = {
    color: "black.1",
    fontWeight: FontWeight.displayRegular,
    borderBottom: "none"
  }

  return (
    <button
      sx={selected ? coreStyles : { ...coreStyles, ...nonSelectedStyles }}
      onClick={onClick}
      aria-pressed={selected}
    >
      <Label2
        sx={{
          variant: selected
            ? `text.${TextVariants.label2Bold}`
            : `text.${TextVariants.label2}`
        }}
      >
        {label}
      </Label2>
    </button>
  )
}

export const ViewOptions: React.FC<BoxProps> = props => {
  const { t } = useTranslation("products")
  const { imageView, setImageView } = useProductListing()
  const googleAnalytics = useGoogleAnalytics()

  return (
    <Flex
      sx={{
        flex: 1,
        alignItems: "center",
        gap: 16
      }}
      {...props}
    >
      <ImageOptionButton
        onClick={() => {
          setImageView("MODEL")
          googleAnalytics.reportViewOptionClick("MODEL")
        }}
        label={t("modelView")}
        selected={imageView === "MODEL"}
      />
      <ImageOptionButton
        onClick={() => {
          setImageView("PRODUCT")
          googleAnalytics.reportViewOptionClick("PRODUCT")
        }}
        label={t("productView")}
        selected={imageView === "PRODUCT"}
      />
    </Flex>
  )
}
