import {
  VerticalDivider,
  SingleColumnLayoutIcon,
  MultiColumnLayoutIcon
} from "@sixty-six-north/ui-system"
import React from "react"
import { Input, Label } from "theme-ui"
import { useLayout } from "../../layout/LayoutState"

export const ColumnModeToggle: React.FC = () => {
  const layout = useLayout()

  const transitionDuration = "0.25s"
  return (
    <div
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        variant: `buttons.outlineLight`,
        backgroundColor: "transparent",
        padding: "0.5em"
      }}
    >
      <Input
        type="checkbox"
        id={"switch"}
        style={{ display: "none" }}
        checked={layout.state.mobileProductsMultiColumnGrid}
        onChange={() => {
          layout.toggleMobileProductsMultiColumnGrid()
        }}
      />
      <Label
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          padding: "0",
          // background: "#F4F4F4",
          // borderRadius: "5px",
          cursor: "pointer",
          position: "relative",
          lineHeight: 1.2
          // variant: "buttons.outlineLight"
        }}
        htmlFor="switch"
      >
        <span
          className="toggle"
          style={{
            position: "absolute",
            display: "block",
            width: "50%",
            height: "100%",
            opacity: "0.66",
            background: "#F4F4F4",
            borderRadius: "10%",
            borderColor: "black",
            borderWidth: "1px",
            transition: transitionDuration,
            transform: layout.state.mobileProductsMultiColumnGrid
              ? "translateX(100%)"
              : undefined
          }}
        />
        <MultiColumnLayoutIcon />
        <VerticalDivider margin={8} />
        <SingleColumnLayoutIcon />
      </Label>
    </div>
  )
}
